<template>
  <div class="animation">Animation <br /><br />
    <a @click="toAnimation1"> 赛车直播(动画1完成)</a><br />
    <a @click="toAnimation2"> 飞艇直播(动画2完成)</a><br />
    <a @click="toAnimation3"> 快乐十分直播(动画3完成)</a><br />
    <a @click="toAnimation4"> 澳洲幸运5(动画4完成)</a><br />
    <a @click="toAnimation4"> 幸运生肖直播(动画4完成)</a><br />
    <a @click="toAnimation1"> 欢乐扑克(动画开发中)</a><br />
    <a @click="toAnimation1"> 鱼虾蟹(动画开发中)</a><br />
    <a @click="toAnimation5"> PC蛋蛋直播(动画5完成)</a><br />

  </div>
</template>
<script>
export default {
  name: 'Animation',
  methods: {
    toAnimation1 () {
      this.$router.push('./Saiche')
    },
    toAnimation2 () {
      this.$router.push('./Feiting')
    },
    toAnimation3 () {
      this.$router.push('./gunQiu')
    },
    toAnimation4 () {
      this.$router.push('./aoZhouLucky')
    },
    toAnimation5 () {
      this.$router.push('./PCdandan')
    }
  }
}
</script>

<style lang="scss" scoped>
.animation {
  height: 500px;
}
</style>
